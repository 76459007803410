import CardWrapper from "components/Common/CardWrapper";
import { useDispatch } from "react-redux";
import {
  Button,
  Col,
  Container,
  Row
} from "reactstrap";
import {
  toggleCurrentModal,
} from "store/actions";
import { getGoldImgSrc } from "helpers/assetImgSrc";

export default function GoldCard({ option, t }) {
  const dispatch = useDispatch();
  const baseCurrency = JSON.parse(localStorage.getItem("GOLD_BASE_CURRENCY"))?.symbol;
  
  return (
    <CardWrapper className="glass-card shadow">
      <Container className="text-center">
        <div>
          <h4 className="mb-2">{option.title}</h4>
        </div>
        <Row className="mb-3">
          {/* Image and pRICE TAG */}
          <Col xs={12} className="mb-3">
            <img
              src={getGoldImgSrc(option?.imageUrl)}
              alt={option.title}
              className="img-fluid"
              style={{ 
                width: "100px",
                height: "100px",
                objectFit: "contain",
              }}
            />
          </Col>
          <Col xs={12} className="mb-3">
            <h5 className="mb-0">{baseCurrency} {option?.price?.toFixed(2)}</h5>
            <p className="text-muted">{t("per quantity")}</p>
          </Col>
        </Row>
        <Row className="mb-3">
          {
            <Button
              className="btn color-bg-btn border-0"
              onClick={() => dispatch(
                toggleCurrentModal("GoldBuy", {
                  title: `${t("Buy")} ${option.title}`,
                  modalData: {
                    ...option,
                    price: option?.price,
                    baseCurrency
                  }
                })
              )}
            >
              {t("Buy")}
            </Button>
          }
        </Row>
      </Container>
    </CardWrapper>
  );
}
