import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProductAddress,
  fetchAddresses,
  toggleCurrentModal
} from "store/actions";
import SharedTransactions from "../Shared/SharedTransactions";
import CardWrapper from "components/Common/CardWrapper";
import moment from "moment";
import { Button } from "reactstrap";

export default function AddressBook() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addresses, loading, clearingCounter } = useSelector((state) => state.goldReducer.address);
  const { currentModal } = useSelector((state) => state.Layout);
  useEffect(() => {
    if (currentModal === "AddressModal") dispatch(toggleCurrentModal(""));
    dispatch(fetchAddresses());
  }, [clearingCounter]);

  const columns = [
    {
      dataField: "recordId",
      text: t("Id"),
    },
    {
      dataField: "createdAt",
      text: t("Created At"),
      formatter: (val) => <span>{moment(val.createdAt).format("DD-MM-YYYY")}</span>,
    },
    {
      dataField: "updatedAt",
      text: t("Updated At"),
      formatter: (val) => <span>{moment(val.updatedAt).format("DD-MM-YYYY")}</span>,
    },
    {
      dataField: "addressLine1",
      text: t("Address Line 1"),
    },
    {
      text: t("Address Line 2"),
      dataField: "addressLine2",
    },
    {
      text: t("city"),
      dataField: "city",
    },
    {
      text: t("Postal Code"),
      dataField: "postalCode",
    },
    {
      text: t("Actions"),
      dataField: "actions",
      formatter: (val) => <div className="text-start">
        <i className="bx bx-edit-alt p-2" style={{ cursor: "pointer" }} onClick={()=> dispatch(toggleCurrentModal("AddressModal", {
          title: t("Edit Address"),
          modalData: {
            isEdit: true,
            ...val
          },
        }))} ></i>
        {/* <i className="bx bx-trash p-2" style={{ cursor: "pointer" }} onClick={()=> dispatch(deleteProductAddress({
          id: val._id,
        }))} ></i> */}
      </div>
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{t("Gold Dashboard")}</title>
        </MetaTags>
        <div className="p-5 m-5">
          <CardWrapper className="glass-card">
            {/* A button on top right of the card to address new address modal */}
            <div className="d-flex justify-content-end">
              <Button className="border-0 color-bg-btn" onClick={()=> dispatch(toggleCurrentModal("AddressModal", {
                title: t("Add Address"),
                modalData: {
                  isEdit: false,
                },
              }))}>
                <i className="bx bx-plus"></i>
              </Button>
            </div>
            <SharedTransactions data={addresses} loading={loading} t={t} columns={columns} />
          </CardWrapper>
        </div>
      </div>
    </React.Fragment>
  );
}
