import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "reactstrap";
import CardWrapper from "components/Common/CardWrapper";
import { withTranslation } from "react-i18next";
import OrderFilter from "./filters/OrderFilter";
import { fetchWallets } from "store/actions";
import Orders from "./Orders";

function WalletTransactions(props) {
  const dispatch = useDispatch();
  const [ordersFilterObj, setOrdersFilterObj] = useState({});
  const { assets } = useSelector(state=>state.assets);
  const { subPortal } = useSelector(state=>state.Layout);
  const { wallets } = useSelector(state=>state.walletReducer);
  const isDemo = subPortal === "DEMO";
  useEffect(() => {
    if (!wallets || wallets.length === 0)
      dispatch(fetchWallets({
        isDemo,
      }));
  }, []);  
  const ordersFilterHandler = (filter) => {
    filter.fromDate === "" && delete filter.fromDate;
    filter.toDate === "" && delete filter.toDate;
    setOrdersFilterObj(filter);
  };


  const mainWallets = wallets.filter(w => !w.isInventory && !w.isCrypto);

  return (
    <>
      <Container className="mb-5 mt-5">
        <div className="d-flex flex-row">
          <h1 className="mb-4">
            {props.t("Order History")}
          </h1>
        </div>
        <OrderFilter filterHandler={ordersFilterHandler} wallets={mainWallets} />
        <CardWrapper className="glass-card">
          <Container>
            <Orders assets={assets} filterObj={ordersFilterObj} isDemo={isDemo} />
          </Container>
        </CardWrapper>
      </Container>
    </>
  );
}

export default withTranslation()(WalletTransactions); 