import logo from "assets/images/brands/finitic.png";
import lLogo from "assets/images/brands/light-logo.png";

export const clientName = "Finitic";
export const developedBy = "Finitic";
export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL || "http://localhost:3001/assets/";
export const cpUrl = process.env.REACT_APP_CP_URL || "http://localhost:4002";
export const apiUrl = process.env.REACT_APP_API_CP_DOMAIN || "http://localhost:3001";
export const companyName =  "Finitic";
export const companyWebsite = "https://www.finitic.com/";
export const privacyPolicyLink = "#";
export const clientAgreementLink = "#";
export const sidebarLogo = logo;
export const smLogo = logo;
export const mainLogo = logo;
export const lightLogo = lLogo;
