import React, { useState, useEffect } from "react";
import {
  useDispatch, useSelector 
} from "react-redux";
import CustomTable from "../../../components/Common/CustomTable";
//i18n
import { withTranslation } from "react-i18next";
import moment from "moment";
import { fetchGoldOrders } from "store/actions";
import { Button } from "reactstrap";

function Orders(props) {
  const { isDemo } = props;
  const [limit, setLimit] = useState(5);
  const { loading, orders = [], ordersPagination } = useSelector((state) => state.goldReducer.orders);
  const { t, filterObj } = props;
  const dispatch = useDispatch();
    
  const columns = [
    {
      dataField: "createdAt",
      text: t("Order Date"),
      formatter: (val) => <span>{moment(val.createdAt).format("DD-MM-YYYY")}</span>,
    },
    {
      dataField: "type",
      text: t("Product Type"),
      formatter: (val) => (<>{val?.asset[0]?.symbol}</>),
    },
    {
      dataField: "type",
      text: t("Title"),
      formatter: (val) => (<>{val?.products?.title}</>),
    },
    {
      dataField: "currency",
      text: t("Currency Used"),
      formatter: (val) => (val.currency),
    },
    {
      dataField: "qty",
      text: t("Quantity"),
      formatter: (item) => item.quantity
    },
    {
      dataField: "amount",
      text: t("Amount"),
      formatter: (item) =>
        parseFloat(item?.amount?.$numberDecimal) || parseFloat(item?.amount) || "-",
    },
    {
      dataField: "delivery",
      text: t("Order Type"),
      formatter: (val) => (val?.type?.split("_").join(" ") || "-"),
    },
    {
      dataField: "delivery",
      text: t("Order Status"),
      formatter: (val) => <>{val?.status}</>
    },
  ];

  // when the filter value changes it fetches data with the new filter
  useEffect(() => {
    const filter = {
      limit:limit,
      page:1,
      // Basic Filter
      status: filterObj?.status,
      currency: filterObj?.wallet,
      type: filterObj?.type,
      // Advanced Filter
      fromDate: filterObj?.fromDate,
      toDate: filterObj?.toDate,
      isDemo
    };
    if (filterObj?.orderType) {
      filter.isDelivery = filterObj?.orderType === "DELIVERY";
    }
    if (filterObj?.goldProduct) {
      filter.goldId = filterObj?.goldProduct;
    }
    dispatch(fetchGoldOrders(filter));
    
  }, [filterObj, limit]);

  return (
    <React.Fragment>
      <CustomTable 
        columns={columns} 
        rows={orders}
        loading={loading}
      />
      {orders?.length > 0 &&
        <Button type="button" className='color-bg-btn border-0 w-100' onClick = {()=>{
          if (ordersPagination?.totalDocs > limit){
            setLimit(limit + 5);
          }
        }
        }
        >{props.t("Load More")}</Button>
      }
    </React.Fragment>
  
  );
}

export default (withTranslation()(Orders)); 