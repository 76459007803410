import React from "react";
import { Redirect } from "react-router-dom";
//Dashboard
import GoldDashBoard from "../pages/Gold/Dashboard";
import Wallet from "../pages/Wallet";
import Documents from "../pages/Documents";
import BankAccounts from "pages/Forex/BankAccounts/BankAccounts";
import Activities from "pages/Forex/Activites";
import Profile from "pages/Authentication/Profile";
import nonAuthRoutes from "./nonAuthRoutes";
import TwoFA from "../pages/Authentication/2FA";
import AddressBook from "pages/Gold/Address";
import History from "pages/Gold/History";
import TradingAccount from "pages/Forex/TradingAccount/index";
import Inventory from "pages/Gold/Inventory";
import Notifications from "pages/Authentication/Notifications";

export default [ 
  {
    path: "/dashboard",
    component: GoldDashBoard,
    isAuth: true
  },
  //profile
  {
    path: "/documents",
    component: Documents,
    isAuth: true 
  },
  {
    path: "/wallet",
    component: Wallet,
    isAuth: true 
  },
  {
    path: "/two-fa",
    component: TwoFA,
    isAuth: true 
  },
  {
    path: "/bank-account",
    component: BankAccounts,
    isAuth: true 
  },
  {
    path: "/activities",
    component: Activities,
    isAuth: true 
  },
  {
    path: "/profile",
    component: Profile,
    isAuth: true 
  },
  {
    path: "/addresses", 
    component: AddressBook,
    isAuth: true
  },
  {
    path:"/trading-account",
    component: TradingAccount,
    isAuth:true,
  },
  {
    path:"/inventory",
    component: Inventory,
    isAuth:true,
  },
  
  {
    path: "/history",
    component: History,
    isAuth: true 
  },
  {
    path: "/notifications",
    component: Notifications,
    isAuth: true
  },
  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    isAuth: true,
    component: () => <Redirect to="/dashboard" /> 
  },
  ...nonAuthRoutes
];