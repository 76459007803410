import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { 
  Container, Input, Row, Col, Label 
} from "reactstrap";
import Select from "react-select";
import moment from "moment";

import { withTranslation } from "react-i18next";
import CardWrapper from "components/Common/CardWrapper";
import { fetchGold } from "../../../../store/actions";
import CustomSelect from "components/Common/CustomSelect";
import { getAssetImgSrc } from "helpers/assetImgSrc";

function OrderFilter(props) {
  const dispatch = useDispatch();
  const [type, setType] = useState();
  const [status, setStatus] = useState();
  const [orderType, setOrderType] = useState();
  const [goldProduct, setGoldProduct] = useState();
  const [wallet, setWallet] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const dateHandler = (e) => {
    const { name, value } = e.target;
    if (name === "fromDate") {
      setFromDate(value);
    } else {
      setToDate(value);
    }
  };

  const { wallets } = props;

  const { gold, loading: productLoading } = useSelector((state) => state.goldReducer.products);

  const typeOptions = [
    {
      label: "GOLD",
      value: "GOLD"
    },
    {
      label: "SILVER",
      value: "SILVER"
    }
  ];

  const statusOptions = [
    {
      label: "PENDING",
      value: "PENDING"
    },
    {
      label: "IN PROGRESS",
      value: "IN_PROGRESS"
    },
    {
      label: "DELIVERED",
      value: "DELIVERED"
    },
    {
      label: "REJECTED",
      value: "REJECT"
    },
    {
      label: "APPROVED",
      value: "APPROVE"
    }
  ];
  const orderTypeOptions = [
    {
      label: "STORAGE",
      value: "STORAGE"
    },
    {
      label: "DELIVERY",
      value: "DELIVERY"
    }
  ];
  console.log(props, "props");
  useEffect(() => {

    props?.filterHandler({
      type,
      fromDate,
      toDate,
      status,
      orderType,
      goldProduct,
      wallet
    });
  }, [type, fromDate, toDate, status, orderType, goldProduct, wallet]);

  useEffect(() => {
    if (!productLoading) {
      dispatch(fetchGold());
    }
  }, []);

  const getGoldOptions = () => {
    if (gold) {
      return gold.map((item) => {
        return {
          label: item.title,
          value: item?._id,
        };
      });
    }
  };

  const getWalletOptions = () => {
    if (wallets) {
      return wallets.map((item) => {
        return {
          value: item.assetId.symbol,
          label: item.assetId.symbol,
          image: getAssetImgSrc(item.assetId)
        };
      });
    }
  };
  
  return (
    <>
      <CardWrapper className="mb-2 glass-card">
        <Container>
          <h4 className="mb-4">
            {props.t("Filter by")}
          </h4>
          <Row className="mb-2">
            {/* type */}
            <Col className="my-4">
              <Label>{props.t("Type")}</Label>
              <CustomSelect
                isClearable={true}
                isSearchable={true}
                options={typeOptions}
                onChange={(e) => setType(e?.value)}
              />
            </Col>

            {/* status */}
            <Col className="my-4">
              <Label>{props.t("Status")}</Label>
              <CustomSelect
                isClearable={true}
                isSearchable={true}
                options={statusOptions}
                onChange={e => setStatus(e?.value)}
              />
            </Col>

            {/* side */}
            <Col className="my-4">
              <Label>{props.t("Order Type")}</Label>
              <CustomSelect
                isClearable={true}
                isSearchable={true}
                options={orderTypeOptions}
                onChange={e => setOrderType(e?.value)}
              />
            </Col>

            {/* market */}
            <Col className="my-4">
              <Label>{props.t("Products")}</Label>
              <div>
                <CustomSelect
                  isClearable
                  isSearchable
                  options={getGoldOptions()}
                  onChange={e => setGoldProduct(e?.value)}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <Label>{props.t("Wallet")}</Label>
              <div>
                <CustomSelect
                  isClearable
                  isSearchable
                  options={getWalletOptions()}
                  onChange={e => setWallet(e?.value)}
                />
              </div>
            </Col>
            {/* fromDate */}
            <Col>
              <Label>{props.t("From Date")}</Label>
              <Input
                className="form-control"
                type="date"
                id="toDate"
                placeholder={props.t("From date")}
                onChange={dateHandler}
                max={toDate ? moment(toDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")}
              />
            </Col>

            {/* toDate */}
            <Col>
              <Label>{props.t("To Date")}</Label>
              <Input
                className="form-control"
                type="date"
                id="toDate"
                placeholder={props.t("To date")}
                onChange={dateHandler}
                max={moment().format("YYYY-MM-DD")}
                min={fromDate && moment(fromDate).format("YYYY-MM-DD")}
              />
            </Col>
          </Row>
        </Container>
      </CardWrapper>
    </>
  );
}


export default (withTranslation()(OrderFilter)); 