import CustomTable from "components/Common/CustomTable";
import React, { useState } from "react";
import { Button, Container } from "reactstrap";


export default function SharedTransactions(props) {
  const { data, loading, columns, t } = props;
  const [limit, setLimit] = useState(3);
  return (
    <>
      <Container className="">
        <div className="pt-2">
          {data && (
            <>
              <CustomTable rows={data.slice(0, limit)} columns={columns} loading={loading} />
              <Button
                disabled={limit >= data?.length}
                type="button"
                className="border-0 color-bg-btn w-100"
                onClick={() => {
                  limit < data?.length && setLimit(limit + 3);
                }}
              >
                {t("Load more")}
              </Button>
            </>
          )}
        </div>
      </Container>
    </>
  );
}
